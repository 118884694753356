/* Add project-specfic styles */

*,
*:before,
*:after {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c6c5c5;
}

::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
}

/* Grid styling */

.ReactTable {
    padding: 0;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    padding: 0 !important;
}

.ReactTable .rt-thead.-header {
    box-shadow: unset;
}

/* Row highlighting */
.ReactTable .rt-table .rt-tbody .rt-tr-group:hover {
    background: rgba(0, 0, 0, 0.05);
}

.no-overflow-y {
    overflow-y: unset;
}

.dialog-content-padding-adjust {
    padding: 8px 16px;
}

input[type='file'] {
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
}

.form-margin {
    margin: 0 10px;
}

.body-cell {
    height: 43px;
    padding-left: 18px !important;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    /* color: var(--primary-text-color)  !important; */
    cursor: pointer !important;

    border-bottom: 1px solid #e0e0e0;
}

.body-header {
    padding-left: 18px;
    font-size: 16px !important;
    background: #f5f5f5 !important;
    font-weight: 500 !important;
    min-height: 42px !important;
    line-height: 0;
    box-sizing: border-box;
    box-shadow: 0 -1px 0 #e0e0e0 inset !important;
}

/* For the Forms */

.section-title {
    opacity: 0.87;
    font-size: 14px;
    height: 34px;
    font-weight: bold;
    text-align: left;
    margin: 0 6px;
    text-transform: uppercase;
    color: var(--pcl-primary-section-title-color);
}

.form-container {
    padding: 16px;
}

.form-margin {
    margin-left: 6px;
    margin-right: 6px;
}

.form-margin-16 {
    margin-left: 16px;
    margin-right: 16px;
}

.form-margin-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.margin-16 {
    margin: 16px;
}

.margin-10 {
    margin: 10px;
}

.padding-12 {
    padding: 12px;
}

.horiz-sep-bottom-1 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

a {
    text-decoration: none;
}

/* Dropdown Menu Placeholder */
.placeholder {
    color: rgba(0, 0, 0, 0.4);
}

.app-county-title {
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    color: black;
    margin-bottom: 8px;
}

.app-county-subtitle {
    font-size: 24px;
    color: #767676;
}

.app-county-img {
    width: 73px;
    height: 60px;
}

.app-signedin-icon {
    width: 64px;
    height: 64px;
    color: var(--pcl-primary-background-color);
}

.app-signedin-time {
    opacity: 0.54;
    font-size: 16px;
    font-weight: bold;
}

.app-signedin-name {
    opacity: 0.87;
    font-size: 16px;
    font-weight: bold;
}

.app-signedin-group-name {
    opacity: 0.87;
    font-size: 14px;
}

.dialog-container {
    width: 320px;
    height: 300px;
}

.dialog-content-padding-adjust {
    padding: 8px 16px;
}

@media screen and (max-width: 768px) {
    .dialog-container {
        width: 100vw;
        height: 100vh;
        max-height: 100% !important;
        margin: 0 !important;
    }
}
